import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/document';

export default {
    getConfidentialityDocument(): Promise<AxiosResponse<IServerResponse<string>>> {
        return axios.get(`${resource}/confidentiality`);
    },

    getCguDocument(): Promise<AxiosResponse<IServerResponse<string>>> {
        return axios.get(`${resource}/cgu`);
    }
}