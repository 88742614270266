<template>
    <div id="parameter-page">
        <toolbar-back-button-component title="Paramètres" class="mb-4"/>

        <span class="text-uppercase ml-4 font-weight-bold">{{ $t('parameter_page.account_parameters') }}</span>
        <v-list class="pa-0 mb-6" color="background">
            <v-list-item-group>
                <v-list-item link :to="'/parameter/manage_space'" data-cy="management-space">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.space_information') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider class="mr-4 ml-4" color="white"/>

                <v-list-item link :to="'/parameter/management_user'" data-cy="management-user">
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.user_management') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider class="mr-4 ml-4" color="white"/>

                <v-list-item link :to="'/parameter/customisation'">
                    <v-list-item-icon>
                        <v-icon>mdi-view-dashboard-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.personalization') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider class="mr-4 ml-4" color="white" v-if="is_devices_paid_by_pro"/>

                <!-- TODO: Just for apple store -> condition to remove -->
                <v-list-item link :to="'/parameter/subscription_payment'"
                             v-if="is_devices_paid_by_pro"
                             :disabled="is_devices_paid_by_pro"
                >
                    <v-list-item-icon>
                        <v-icon>mdi-credit-card-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.subscription_payment_method') }}</v-list-item-title>
                        <v-list-item-subtitle class="font-italic" v-if="is_devices_paid_by_pro">
                            {{ $t('parameter_page.subscription_is_take') }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <span class="text-uppercase ml-4 font-weight-bold">{{ $t('parameter_page.assistance') }}</span>
        <v-list class="pa-0 mb-6" color="background">
            <v-list-item-group>
                <!--
                                <v-list-item link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-application-cog-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Fonctionnement
                                            <span class="font-italic text&#45;&#45;lighten-2"> Guide utilisateur</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider class="mr-4 ml-4" color="white"/>


                                <v-list-item link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-lifebuoy</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            Aide
                                            <span class="font-italic text--lighten-2">FAQ</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-divider class="mr-4 ml-4" color="white"/>
                -->
                <v-list-item link :to="'/parameter/comment'">
                    <v-list-item-icon>
                        <v-icon>mdi-comment-eye-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.send_comment') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>

        <span class="text-uppercase ml-4 font-weight-bold">{{ $t('parameter_page.general_conditions') }}</span>

        <v-list class="pa-0 mb-4" color="background">
            <v-list-item-group>
                <v-list-item @click="getDocument('confidentiality')">
                    <v-list-item-icon>
                        <v-icon>mdi-lock-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.privacy_policy') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider class="mr-4 ml-4" color="white"/>

                <v-list-item @click="getDocument('cgu')">
                    <v-list-item-icon>
                        <v-icon>mdi-text-box-check-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('parameter_page.terms_conditions_use') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <p class="display-version version-parameter ml-3 mb-2">{{ $t('parameter_page.version') }} {{ app_version }}</p>
    </div>
</template>

<script>
import DocumentRepository from "@/repositories/DocumentRepository";
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";

export default {
    name: "ParameterPage",
    components: {ToolbarBackButtonComponent},
    computed: {
        is_devices_paid_by_pro: {
            get() {
                return this.$store.getters["tanks/getDevicesContractPaidByPro"];
            }
        }
    },
    methods: {
        getDocument(document) {
            if (document === 'confidentiality') {
                DocumentRepository.getConfidentialityDocument().then((success) => {
                    window.open(success.data.data);
                }).catch((error) => {
                    this.manageError(error)
                });
            } else {
                DocumentRepository.getCguDocument().then((success) => {
                    window.open(success.data.data);
                }).catch((error) => {
                    this.manageError(error)
                });
            }
        },
    }
}
</script>

<style>
.version-parameter {
    position: absolute;
    bottom: 5px;
    left: 5px;
}
</style>